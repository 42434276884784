<template>
  <a-row id="globalHeader" align="center" :wrap="false">
    <a-col flex="auto">
      <a-menu
        mode="horizontal"
        :selected-keys="selectedKeys"
        @menu-item-click="doMenuClick"
      >
        <a-menu-item
          key="0"
          :style="{ padding: 0, marginRight: '38px' }"
          disabled
        >
          <div class="titleBar">
            <img class="logo" src="../assets/edu1.svg" style="height:35px;width:35px;" />
            <div class="title" style="font-size: 18px; font-family: YuYangTi;">
              <!-- <h2 style="font-size: 20px; font-family: YuYangTi;">智慧测评</h2> -->
               智慧测评
            </div>
          </div>
        </a-menu-item>
        <a-menu-item v-for="item in visibleRoutes" :key="item.path">
          {{ item.name }}
        </a-menu-item>
      </a-menu>
    </a-col>
    <a-col flex="100px">
      <div v-if="loginUserStore.loginUser.id">
        <!-- {{ loginUserStore.loginUser.userName ?? "无名" }} -->
          <div :style="{ display: 'flex', alignItems: 'center' }">
                <a-avatar
                  :size="24"
                  :image-url="loginUserStore.loginUser?.userAvatar"
                  :style="{ marginRight: '8px' }"
                />
                <!-- <a-typography-text
                  >
                </a-typography-text> -->
                <a-dropdown @select="handleSelect">
                  <a-button type="text">{{ loginUserStore.loginUser?.userName ?? "无名" }}</a-button>
                  <template #content>
                    <a-doption>注销</a-doption>
                  </template>
                </a-dropdown>

              </div>
      </div>
      <div v-else>
        <a-button type="primary" href="/user/login">登录</a-button>
      </div>
    </a-col>
  </a-row>
</template>

<script setup lang="ts">
import { routes } from "@/router/routes";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useLoginUserStore } from "@/store/userStore";
import checkAccess from "@/access/checkAccess";
import { userLogoutUsingPost } from "@/api/userController";
import message from "@arco-design/web-vue/es/message";

const loginUserStore = useLoginUserStore();

const handleSelect = async () => {
      const res = await userLogoutUsingPost()
      if (res.data.code === 0) {
        message.success("注销成功");
        router.push({
          path: "/user/login",
          replace: true,
        });
      }
    };


const router = useRouter();
// 当前选中的菜单项
const selectedKeys = ref(["/"]);
// 路由跳转时，自动更新选中的菜单项
router.afterEach((to, from, failure) => {
  selectedKeys.value = [to.path];
});

// 展示在菜单栏的路由数组
const visibleRoutes = computed(() => {
  return routes.filter((item) => {
    if (item.meta?.hideInMenu) {
      return false;
    }
    // 根据权限过滤菜单
    if (!checkAccess(loginUserStore.loginUser, item.meta?.access as string)) {
      return false;
    }
    return true;
  });
});

// 点击菜单跳转到对应页面
const doMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};
</script>

<style scoped>
#globalHeader {
}

.titleBar {
  display: flex;
  align-items: center;
}

.title {
  margin-left: 16px;
  color: black;
}

.logo {
  height: 48px;
}
</style>
